import { useState } from 'react';
import './CategoryCheckbox.css';

const CategoryCheckbox = ({ category, onChange }) => {

    const [isChecked, setIsChecked] = useState(category.active)

    // useEffect(() => {
    //     setIsChecked(category.active);
    // }, [category.active]);

    const checkHandler = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        if (onChange) {
            onChange(category.value, newCheckedState);
        }
    };

    const getIconClasses = () => {
        return isChecked ? 'category-checkbox-icon active' : 'category-checkbox-icon';
    }

    const getLabelClasses = () => {
        return isChecked ? 'category-checkbox-label active' : 'category-checkbox-label';
    }

    return (
        <div className='category-checkbox-container'>
            <input
                id={category.value}
                type="checkbox"
                className="d-none"
                checked={isChecked}
                readOnly
            />
            <label
                htmlFor={category.value}
                className='d-flex flex-column justify-content-center align-items-center'
                onClick={checkHandler}
            >
                <div className={getIconClasses()}>{category.svg()}</div>
                <div className={getLabelClasses()}>{category.label}</div>
            </label>
        </div>
    );
};

export default CategoryCheckbox;
