import { useState } from 'react';
import { MarkerF, InfoWindowF } from '@react-google-maps/api';

// import MapsMarker from './../maps-marker.svg';

const CustomMapMarker = ({ location, onClick, allowTooltipShown }) => {

  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick(location);
    }
  }

  const handleMouseOver = () => {
    setShowInfoWindow(true);
  };

  const handleMouseOut = () => {
    setShowInfoWindow(false);
  };

  const getIcon = () => {
    if (!location.active) {
        return {
            url: process.env.REACT_APP_ASSET_PATH + '/icons/maps-marker.svg', // Replace with your actual icon URL
            // url: MapsMarker,
            scaledSize: new window.google.maps.Size(38, 46), // Scale the icon size
        };
    }
    else /*if (location.active)*/ {
        return {
            url: process.env.REACT_APP_ASSET_PATH + '/icons/maps-marker-center.svg', // Replace with your actual icon URL
            scaledSize: new window.google.maps.Size(38, 46), // Scale the icon size
        };
    }
    // else if (!location.active && location.isGoldPartner) {
    //     return {
    //         url: process.env.REACT_APP_ASSET_PATH + '/icons/maps-marker-gold.svg', // Replace with your actual icon URL
    //         scaledSize: new window.google.maps.Size(38, 46), // Scale the icon size
    //     };
    // }
    // else if (location.active && location.isGoldPartner) {
    //     return {
    //         url: process.env.REACT_APP_ASSET_PATH + '/icons/maps-marker-gold-center.svg', // Replace with your actual icon URL
    //         scaledSize: new window.google.maps.Size(38, 46), // Scale the icon size
    //     };
    // }
  };

  return (
    <>
      <MarkerF
      position={{ lat: location.lat, lng: location.lng }}
      icon={getIcon()} // Use the icon determined by the type
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      />
      {showInfoWindow && allowTooltipShown && (
        <InfoWindowF position={{ lat: location.lat, lng: location.lng }} options={{ zIndex: 5, pixelOffset: new window.google.maps.Size(0, -47), headerDisabled: true }}>
          <div className='ütze'>{location.name}</div>
        </InfoWindowF>
      )}
    </>
  );
};

export default CustomMapMarker;
