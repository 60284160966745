import './SearchResultItem.css';

const SearchResultItem = ({ location, onMouseEnter, onMouseLeave, onClick, index, isSelected }) => {

  const handleMouseEnter = () => {
    if (onMouseEnter) {
      onMouseEnter(location, index);
    }
  };

  const handleMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave(location, index);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick(location, index);
    }
  };

  const getIcon = () => {
    if (location.isGoldPartner) {
        return process.env.REACT_APP_ASSET_PATH + '/images/siegel-premium-gold.png';
    }
    return process.env.REACT_APP_ASSET_PATH + '/images/siegel-premium.svg';
  }

  const getStyles = () => {
    return isSelected
      ? 'search-result-item-container active d-flex flex-row align-items-center'
      : 'search-result-item-container d-flex flex-row align-items-center';
  }

  return (
    <div
      className={getStyles()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img className='search-result-item-icon' alt="Premium Partner" loading="lazy" decoding="async" data-nimg="1"
        src={getIcon()}>
      </img>
      <div className='flex-grow-1 ps-4'>
        <div className='flex-column'>
          <div className='search-result-item-name d-flex flex-row justify-content-between'>
            <div className='me-1'>{location.name}</div>
            <div className='d-flex flex-row'>
              <svg className='flex-shrink-0 me-1' xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24"><path stroke="none" d="M0 0h24v24H0z"></path><path d="M3 11a1 1 0 1 1-1 1.013 1 1 0 0 1 1-1zM21 11a1 1 0 1 1-1 1.013 1 1 0 0 1 1-1zM8 12h8M13 9l3 3-3 3"></path></svg>
              <span>{location.distance}&nbsp;km</span>
            </div>
          </div>
          <div className='search-result-item-address'>{location.street}, {location.zipcode} {location.city}</div>
          <div className='search-result-item-services d-flex flex-row gap-x-2 mt-2'>
            <div className={location.hasPvSpeicher && location.hasEMobility && location.hasWaermepumpeInstall ? 'search-result-item-service active' : 'search-result-item-service'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.26 53.38"><path fill="currentColor" d="m24.34 37.55-9.48-9.28 2.8-2.86 6.65 6.51L40.34 15.8l2.82 2.83z"></path><path fill="currentColor" d="m40.34 15.8 2.83 2.83-18.83 18.92-9.48-9.28 2.8-2.86 6.65 6.51z"></path><path fill="currentColor" d="m53.22 30.83.04-7.98-6.92-1.96q-.285-.99-.66-1.95l-1.1 1.11-2.78 2.79c1.31 5.22-.08 10.98-4.17 15.03-.99.98-2.09 1.8-3.25 2.47-.11.06-.22.12-.32.18-.65.35-1.32.65-2.01.91-.12.05-.24.1-.36.14-.37.13-.75.24-1.12.34-.22.06-.44.1-.65.15-.54.12-1.09.2-1.63.26-.35.04-.7.06-1.05.08-.92.04-1.85 0-2.77-.13-.29-.04-.58-.08-.86-.13-.12-.02-.25-.06-.37-.08-.41-.09-.82-.19-1.22-.32-.02 0-.04-.01-.06-.02-6.39-2.01-11.03-7.98-11.03-15.02 0-8.07 6.11-14.74 13.95-15.63.07 0 .14-.02.21-.03.21-.02.42-.02.63-.03 4.33-.23 8.74 1.29 12.02 4.6l1.19-1.2 1.41-1.42 1.42 1.42 2.83 2.83.66.66c-.14-.31-.3-.62-.46-.92l3.56-6.19-.04-.04.04-.08-5.64-5.64s-4.64 2.62-6.3 3.56C35.2 7.94 33.92 7.41 32.58 7L30.71.04h-.04V.02h-4.04L22.74 0v.02h-.04s-1.41 5.08-1.93 6.94c-1.32.39-2.6.91-3.83 1.56l-6.27-3.61L5 10.52s.02.03.04.08l-.04.04s2.55 4.5 3.51 6.22c-.67 1.22-1.2 2.5-1.6 3.81l-.23.06c.07.02.13.03.2.05h.03L0 22.68v7.98l6.97 1.94c.4 1.34.93 2.62 1.58 3.83l-3.58 6.29 5.64 5.64s4.64-2.62 6.3-3.56c.07.04.14.06.2.1.55.29 1.09.55 1.66.79 0 0 .02 0 .03.01.58.24 1.16.48 1.75.66l1.88 6.98h.22v.02h3.83l3.92.02v-.02h.22l1.94-6.97c1.29-.39 2.52-.9 3.68-1.51l6.23 3.59 5.67-5.61s-2.6-4.65-3.53-6.32c.67-1.23 1.21-2.51 1.61-3.82l6.98-1.88h.02Z"></path></svg>
            </div>
            <div className={location.hasPvSpeicher ? 'search-result-item-service active' : 'search-result-item-service'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.2 47"><path fill="currentColor" d="m51.2 8 3-8zM46 21.8h.1l3.4-9.2h-.1z"></path><path fill="currentColor" d="M32.8 21.8H46l3.4-9.2H36.3zM51.2 8l3-8h-13l-3.1 8zM27.9 21.8l3.5-9.2H19.7l-3.5 9.2zM33.2 8l3.1-8H24.6l-3.1 8zM16.6 8l3-8H8.2l-3 8zM14.8 12.6H3.5L0 21.8h11.3zM27.4 40V25.5h-4.6V40c-6.5.4-11.5 2.7-11.5 5.5v1.6h27.5v-1.6c0-2.8-5-5.1-11.5-5.5Z"></path></svg>
            </div>
            <div className={location.hasEMobility ? 'search-result-item-service active' : 'search-result-item-service'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 59"><path fill="none" d="M18.7 10H32V4h-2.9c-5.7 0-10.4 6-10.4 6M36 4v6h16.5S43.3 4 39 4z"></path><path fill="currentColor" d="M59.7 23c0 .3-.1.6-.1 1v1c.6 1.7 2.1 3 4 3s3.4-1.3 3.9-3c0-.3.1-.7.1-1 0-2.2-1.8-4-4-4s-3.4 1.3-3.9 3M19.7 20.2c-1.8 0-3.3 1.3-3.7 2.9 0 .3-.2.7-.2 1v.8c.4 1.8 1.9 3.1 3.8 3.2h.1c1.9 0 3.5-1.4 3.9-3.2v-.8c0-2.2-1.8-4-4-4h-.1Z"></path><path fill="currentColor" d="M74.9 16.9c-.3-.8-1.1-2.2-3.4-3.3-3-1.5-8.5-3.2-12.7-4.2-2.2-1.5-8-4.7-13.7-7.3-2-.9-5.1-2.1-7.9-2.1h-7.5c-7.8 0-17.8 9-17.8 9l-6 1v11.3c.4-.1.9-.2 1.3-.2l-1.3.9v-.7c-3.5.9-6 4-6 7.8V31c0 4.4 3.6 8 8 8.1h54.3c2.2 0 4.1 1.8 4.1 4.1v1.9c0 2.2-1.8 4.1-4.1 4.1H43.6v4h18.6c4.4 0 8-3.6 8-8.1v-1.9c0-4.4-3.6-8-8-8.1H8.1C5.9 35.1 4 33.3 4 31v-1.9C4 26.9 5.8 25 8.1 25h4.7v-.8c0-2 .8-3.7 2.1-5 1.2-1.2 2.9-2 4.8-2 3.8 0 6.9 3.2 6.9 7V26h30.2v-2c0-2.2 1-4.2 2.6-5.5.3-.2.5-.4.8-.6 1.4-.8 3.1-1.2 4.9-.8 3.5.7 5.8 3.9 5.6 7.4v1.4h4.2l1.4-3.9s-1-4.8-1.1-5.1ZM32 10H18.7s4.7-6 10.4-6H32zm4 0V4h3c4.3 0 13.5 6 13.5 6z"></path><path fill="currentColor" d="M37 43h-5v3h-6v3h6v4h-6v3h6v3h5.1c3.3-.4 5.9-2.8 6.7-6v-4c-.8-3.1-3.5-5.6-6.7-6ZM7.3 21c-.4 0-.9.1-1.3.2v.7z"></path></svg>
            </div>
            <div className={location.hasWaermepumpeInstall ? 'search-result-item-service active' : 'search-result-item-service'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.2 55.28"><g fill="currentColor"><path d="M64.18 5.4v2.02h-7.43V0c-2.97 1.38-4.93 4.31-4.93 7.81 0 3.21 1.74 6.01 4.33 7.52v22.38c0 2.39 1.97 4.33 4.38 4.33s4.27-1.94 4.27-4.33V15.37a8.69 8.69 0 0 0 4.41-7.57c0-3.62-1.76-6.49-5.03-7.8zM61.7 37.7a1.24 1.24 0 1 1-2.48 0 1.24 1.24 0 0 1 2.48-.01Z" ></path><path d="M48.5 22.08v27.7h-43v-27.7zm5.5-5.51H0v38.71h54z" ></path><path d="M32.99 26.29h12.16v3.25H32.99zM32.99 32.1h12.16v3.25H32.99zM32.99 37.9h12.16v3.25H32.99zM19.21 24.87c-6.34 0-11.47 5.14-11.47 11.47s5.14 11.47 11.47 11.47 11.47-5.14 11.47-11.47-5.14-11.47-11.47-11.47m8.1 18.94c-1.28 1.39-3.08 1.82-5.21-.14-1.62-1.5-2.23-4.75-2.43-6.22-.14.06-.3.1-.47.1-.21 0-.41-.06-.59-.16-.79 1.36-2.48 3.93-4.44 4.67-2.71 1.03-4.22-.03-4.89-1.8-.68-1.77-.26-3.57 2.45-4.6 1.9-.73 4.76-.01 6.29.48.07-.38.31-.7.65-.87-.71-1.47-1.75-4.11-1.32-6.04.64-2.83 2.36-3.5 4.21-3.08s3.11 1.76 2.48 4.59c-.44 1.96-2.58 3.92-3.84 4.94.13.19.2.42.2.67 0 .13-.03.26-.07.38 1.48.07 4.78.41 6.4 1.91 2.13 1.96 1.85 3.79.57 5.18h.01Z"></path></g></svg>
            </div>
            <div className={location.hasWaermepumpeIntegration ? 'search-result-item-service active' : 'search-result-item-service'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.82 60.36"><g fill="currentColor"><path d="M48.5 26.7v27.7h-43V26.7zm5.5-5.51H0V59.9h54z" ></path><path d="M32.99 30.9h12.16v3.25H32.99zM32.99 36.71h12.16v3.25H32.99zM32.99 42.51h12.16v3.25H32.99zM19.21 29.49c-6.34 0-11.47 5.14-11.47 11.47s5.14 11.47 11.47 11.47 11.47-5.14 11.47-11.47-5.14-11.47-11.47-11.47m8.1 18.94c-1.28 1.39-3.08 1.82-5.21-.14-1.62-1.5-2.23-4.75-2.43-6.22-.14.06-.3.1-.47.1-.21 0-.41-.06-.59-.16-.79 1.36-2.48 3.93-4.44 4.67-2.71 1.03-4.22-.03-4.89-1.8-.68-1.77-.26-3.57 2.45-4.6 1.9-.73 4.76-.01 6.29.48.07-.38.31-.7.65-.87-.71-1.47-1.75-4.11-1.32-6.04.64-2.83 2.36-3.5 4.21-3.08s3.11 1.76 2.48 4.59c-.44 1.96-2.58 3.92-3.84 4.94.13.19.2.42.2.67 0 .13-.03.26-.07.38 1.48.07 4.78.41 6.4 1.91 2.13 1.96 1.85 3.79.57 5.18h.01ZM55.63 37.95v-3h3.05c2.55 0 4.55 2.78 4.55 6.32v9.18c0 1.83 1.49 3.32 3.32 3.32h9.54v3h-9.54c-3.48 0-6.32-2.83-6.32-6.32v-9.18c0-2-.93-3.32-1.55-3.32z" ></path><path d="M75.84 50.2a5.08 5.08 0 1 1-.002 10.162A5.08 5.08 0 0 1 75.84 50.2M55.63 28.99h21.36v3H55.63z" ></path><path d="M76.74 35.57a5.08 5.08 0 1 0-.002-10.162 5.08 5.08 0 0 0 .002 10.162M55.63 22.41v3h3.05c2.55 0 4.55-2.78 4.55-6.32V9.91c0-1.83 1.49-3.32 3.32-3.32h9.54v-3h-9.54c-3.48 0-6.32 2.83-6.32 6.32v9.18c0 2-.93 3.32-1.55 3.32z" ></path><path d="M75.84 10.16a5.08 5.08 0 1 0-.002-10.162 5.08 5.08 0 0 0 .002 10.162" ></path></g></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultItem;
