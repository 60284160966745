import { useState, useEffect } from 'react';
import './ContactForm.css';

const emptyFormData = {
    name: '',
    zipcode: '',
    street: '',
    streetnr: '',
    email: '',
    phone: '',
    weitergabe: false,
    agb: false
};

const ContactForm = ({ location, action, onClose, filterCity, filterServices }) => {
    const [formData, setFormData] = useState({ ...emptyFormData });
    const [isSubmitEnabled, setSubmitEnabled] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    useEffect(() => {
        if (location) {
            setFormData({...formData, id: location.id});
        }
    }, [location]);

    useEffect(() => {
        if (filterCity) {
            setFormData({...formData, filter_city: filterCity});
        }
    }, [filterCity]);

    useEffect(() => {
        if (filterServices) {
            setFormData({...formData, filter_services: filterServices});
        }
    }, [filterServices]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        const newFormData = { ...formData, [name]: newValue };
        setFormData(newFormData);
        checkSubmitEnabled(newFormData);
    };

    const checkSubmitEnabled = (data) => {
        // Check if all required fields and checkboxes are filled/checked
        const isFormValid = data.name && data.zipcode && data.street && data.streetnr && data.email && data.phone && data.weitergabe && data.agb;
        setSubmitEnabled(isFormValid);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setSubmitting(true); // Disable form while submitting

        try {

            // Get username and password from environment variables
            const username = process.env.REACT_APP_CONTACT_FORM_AUTH_USERNAME;
            const password = process.env.REACT_APP_CONTACT_FORM_AUTH_PASSWORD;

            // Create Basic Auth header
            const authHeader = 'Basic ' + btoa(`${username}:${password}`);

            // Simulate AJAX call to fake endpoint
            const response = await fetch(action, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authHeader,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Show success message
                setSuccess(true);

                // Wait for 2 seconds before calling the close handler
                setTimeout(() => {
                    setFormData(emptyFormData);
                    setSuccess(false);
                    onClose();
                }, 2000);
            } else {
                console.error("Error submitting the form");
            }
        } catch (error) {
            console.error("AJAX error:", error);
        } finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 500);
        }
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    {/* original adds utm, filter_search and filter_services to form */}

    // if (location) {
        return (
            <div className={`contact-form-container ${location ? 'visible' : ''}`}>
                <div className='contact-form-loader'></div>
                { location ? (
                <form method='POST' name="contact-form" onSubmit={handleFormSubmit}>
                    <div className='row p-lg-5 py-2 px-3'>
                        <div className='col-lg-12 contact-form-claim'>
                            <div className='d-flex flex-row'>
                                <div><span>Jetzt Beratungstermin</span> mit <a href={location.website} target="_blank" rel="noopener"><span className="contact-form-open">{location.name}<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5m5-3h6v6m-11 5L20.2 3.8"></path></svg></span></a> <span>vereinbaren</span>!</div>
                                <div onClick={handleClose} className='contact-form-close flex-shrink-0'><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24"><path d="M18 6 6 18M6 6l12 12"></path></svg></div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-4 d-flex flex-column'>
                            <label htmlFor="name" className="form-control-label mx-2"><span className="px-2">Vor- und Nachname *</span></label>
                            <input className="form-control" type="text" placeholder='Vor- und Nachname' name="name" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-md-6 mt-4 d-flex flex-column'>
                            <label htmlFor="zipcode" className="form-control-label mx-2"><span className="px-2">PLZ *</span></label>
                            <input className="form-control" type="text" placeholder='Postleitzahl' name="zipcode" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-md-6 mt-4 d-flex flex-column'>
                            <label htmlFor="street" className="form-control-label mx-2"><span className="px-2">Straße *</span></label>
                            <input className="form-control" type="text" placeholder='Straße' name="street" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-md-6 mt-4 d-flex flex-column'>
                            <label htmlFor="streetnr" className="form-control-label mx-2"><span className="px-2">Hausnummer *</span></label>
                            <input className="form-control" type="text" placeholder='Hausnummer' name="streetnr" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-md-6 mt-4 d-flex flex-column'>
                            <label htmlFor="email" className="form-control-label mx-2"><span className="px-2">E-Mail-Adresse *</span></label>
                            <input className="form-control" type="email" placeholder='E-Mail' name="email" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-md-6 mt-4 d-flex flex-column'>
                            <label htmlFor="phone" className="form-control-label mx-2"><span className="px-2">Telefonnummer *</span></label>
                            <input className="form-control" type="text" placeholder='Telefonnummer' name="phone" required onChange={handleInputChange} disabled={isSubmitting} />
                        </div>
                        <div className='col-12 mt-4 d-flex flex-row align-items-center'>
                            <input className="form-checkbox me-2" type="checkbox" name="weitergabe" required
                                checked={formData.weitergabe}
                                onChange={handleInputChange}
                                disabled={isSubmitting}
                            />
                            <label htmlFor="weitergabe" className="form-checkbox-label"><span>Meine Kontaktdaten dürfen an den E3/DC-Fachpartner weitergegeben werden.*</span></label>
                            {formData.weitergabe ? (
                                <svg className="form-checkbox-check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            ) : <></>}
                        </div>
                        <div className='col-12 mt-2 d-flex flex-row align-items-center'>
                            <input className="form-checkbox me-2" type="checkbox" name="agb" required
                                checked={formData.agb}
                                onChange={handleInputChange}
                                disabled={isSubmitting}
                            />
                            <label htmlFor="agb" className="form-checkbox-label"><span>Ich habe die <a target="_blank" href="/datenschutz/">Datenschutzbedingungen</a> gelesen und akzeptiere diese.*</span></label>
                            {formData.agb ? (
                                <svg className="form-checkbox-check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            ) : <></>}
                        </div>
                        <div className='col-12 my-4 d-flex justify-content-center'>
                            <input className="d-none" type="hidden" name="filter_city" value={filterCity} />
                            <input className="d-none" type="hidden" name="filter_services" value={filterServices} />
                            <input className="d-none" type="hidden" name="id" value={location.id} />
                            {!isSuccess ? (
                                <button className='contact-form-btn py-2' disabled={!isSubmitEnabled || isSubmitting}>
                                    {isSubmitting ? (
                                    // Show loading spinner while submitting
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Senden...</span>
                                    </div>
                                    ) : 'Jetzt Termin vereinbaren'}
                                </button>
                            ) : (
                                <div className='contact-form-success'>Vielen Dank! Wir haben Ihre Kontaktanfrage erhalten!</div>
                            )}
                        </div>
                    </div>
                </form>
                ) : (<></>)
            }
            </div>
        );
    // }
};

export default ContactForm;
