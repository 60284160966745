import { useState, useEffect } from 'react';
import './CategoryMultiSelect.css';
import CategoryCheckbox from "./CategoryCheckbox";

const CategoryMultiSelect = ({ categories, onChange }) => {

    const [selectedCategories, setSelectedCategories] = useState({});

    useEffect(() => {
        var selected = {};
        categories.forEach(element => {
            selected[element.value] = element.active;
        });
        setSelectedCategories(selected);
    }, [categories]);

    // const handleCheckboxChange = (categoryValue, isChecked) => {
    //     // console.log('categoryValue', categoryValue);
    //     // console.log('isChecked', isChecked);
    //     // console.log('oldCategries', categories);

    //     var tmpCategories = selectedCategories;
    //     tmpCategories[categoryValue] = isChecked;
    //     setSelectedCategories(tmpCategories);

    //     if (onChange) {
    //         onChange(tmpCategories);
    //     }
    // };

     useEffect(() => {
        if (onChange) {
            onChange(selectedCategories);
        }
    }, [selectedCategories]);

    const handleCheckboxChange = (categoryValue, isChecked) => {
        setSelectedCategories(prevState => {
            return { ...prevState, [categoryValue]: isChecked, }
        });
        // state is not immediately updated here?
        // if (onChange) {
        //     onChange(selectedCategories);
        // }
    };

    return (
        <div className='category-multi-select-container px-3 pb-2 d-flex justify-content-between'>
            {categories.map((category, index) => (
                <CategoryCheckbox
                    key={index}
                    category={category}
                    onChange={handleCheckboxChange}
                />
            ))}
        </div>
    );
};

export default CategoryMultiSelect;
